import { Validator } from "simple-vue-validator";
import TRACE_API from "@/api/TRACE_API.js";


export default {
    name: "OperationExecutions",

    components: {},

    data() {
        return {
            Filter: {
                filExecutionDateTimeRange: {
                    Start: null,
                    End: null
                },

                filIncludeExecutionDate: false,
                filWithErrorResponse: false,
                filHasException: false,

                UserAlias: null,
                HeadersJSON: null,
                ParamsJSON: null,

                Operation: {
                    ApplicationName: null
                }
            },

            PaggingInfo: VueUtils.GetPaggingInfoInstance(),

            pagOperations: null,

            lsts: {
                ApplicationNames: []
            },

            txts: {
                NoResultsMsg: Constants.NoResultsMsg
            }
        }
    },

    computed: {
        flsDynOperations () {
            var result = [
                { key: "Operation.ApplicationName", label: "Aplicación" },
                { key: "Operation.OperationName", label: "Operación" },
                { key: "UserCount", label: "Usuarios" },
                { key: "ExecutionCount", label: "Ejecuciones" }];

            if (this.Filter.filIncludeExecutionDate) {
                result.push ({ key: "ExecutionDate", label: "Fecha Ejecución" });
            }

            return result;
        }
    },

    async mounted() {
        SetDatePickerRangeByIDs('txtExecutionDateTimeStart', 'txtExecutionDateTimeEnd');
        
        this.lsts.ApplicationNames = await TRACE_API.GetApplicationNames().then(ConvertArrayToOptions);

        this.Clear();
    },

    validators: {
        "Filter.filExecutionDateTimeRange.Start": function (pValue) {
            return Validator.value(pValue).required();
        }
    },

    methods: {

        Clear() {
            this.Filter.filExecutionDateTimeRange.Start = window.SetAsStartDate (new Date ()); 
            this.Filter.filExecutionDateTimeRange.End = null;
            this.Filter.Operation.ApplicationName = null;
            this.Filter.filIncludeExecutionDate = false;
            this.Filter.filWithErrorResponse = false;
            this.Filter.filHasException = false;
            this.Filter.UserAlias = null;
            this.Filter.HeadersJSON = null;
            this.Filter.ParamsJSON = null;

            this.pagOperations = null;
            this.PaggingInfo.Clear ();
        },

        async BrowseOperationExecutions(pResetPageIndex) {

            this.PaggingInfo.CheckResetPageIndex(pResetPageIndex);

            this.ValidateNested(async () => {
                var result = await TRACE_API.GetOperationExecutions({
                    Filter: this.Filter,
                    PaggingInfo: this.PaggingInfo.GetBaseZeroFromOne()
                });

                this.pagOperations = result.Results;
                this.PaggingInfo.SetFromBaseZero(result.PaggingInfo);
            });
        }
    }
}
